@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --background: #FFFFFF;
  --foreground: #FFFFFF;

  --base-100: #F6F6F6; /* Surfaces */
  --base-200: #F1F1F1; /* Surfaces */
  --base-300: #E8E8E8; /* Borders */
  --base-400: #DCDCDC; /* Borders */
  --base-500: #B9B9B9; /* Disabled text */
  --base-600: #8B8B8B; /* Tertiary text & Icons */
  --base-700: #6B6B6B; /* Secondary text */
  --base-800: #5D5D5D; /* Icons */
  --base-900: #171717; /* Primary text */ /* BASE COLOR */

  --secondary-100: #EFF3F7;
  --secondary-200: #D7E2EC;
  --secondary-300: #AFC5D9;
  --secondary-400: #87A8C5;
  --secondary-500: #5F8BB2; /* BASE COLOR */
  --secondary-600: #567DA0;
  --secondary-700: #4C6F8E;
  --secondary-800: #3E5A74;
  --secondary-900: #304659;


  --primary-200: #e0ebff;
  --primary-300: #bed4ff;
  --primary-400: #6095fd;
  --primary-500: #4584ff;
  --primary-600: #0057ff; /* BASE COLOR */
  --primary-700: #0049d6;
  --primary-800: #0040bb;
  --primary-900: #00349b;

  --destructive-200: #FEEAE9;
  --destructive-300: #FDCBC8;
  --destructive-400: #FC9891;
  --destructive-500: #FB645B; 
  --destructive-600: #F93124; /* BASE COLOR */
  --destructive-700: #E02C20;
  --destructive-800: #C7271D;
  --destructive-900: #A22017;

  --warning-200: #FFF5E5;
  --warning-300: #FFE5BF;
  --warning-400: #FFCC80;
  --warning-500: #FFB340; 
  --warning-600: #FF9900; /* BASE COLOR */
  --warning-700: #E58A00;
  --warning-800: #CC7A00;
  --warning-900: #A66300;

  --affirmative-200: #EAF8F2;
  --affirmative-300: #CAEEDF;
  --affirmative-400: #95DEBF;
  --affirmative-500: #60CD9F; 
  --affirmative-600: #2BBC7F; /* BASE COLOR */
  --affirmative-700: #27A972;
  --affirmative-800: #229666;
  --affirmative-900: #1C7A53;

  --event-header-height: 4rem;
  --event-footer-height: 5.125rem;
}

@media (prefers-color-scheme: dark) {
  :root {
    --text-base-color: 255, 255, 255;
  }
}

body {
  color: rgb(var(--base-900));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.react-joyride__spotlight {
  border: 2px solid #003bad;
  box-shadow: 0px 0px 12px 0px #0057FF4D;
}